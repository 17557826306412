import "./ExcalidrawLogo.scss";

const LogoIcon = () => (
  <svg id="pencil" viewBox="0 0 96 77" x="32" y="6.5" width="77" height="77">
      <g
          transform="translate(52 -6) rotate(30 0 0)"
          stroke-width="4"
          stroke="#A7A5F9"
          stroke-linecap="round"
          stroke-linejoin="round"
          fill="none">
              <path
                  d="M 0 15 h 32 h -32 v -10 a 5 5 0 0 1 5 -5 h 22 a 5 5 0 0 1 5 5 v 10 v 40 h -32 l 16 18 l 16 -18 h -10 v -40 h -12 v 40 h -10 z">
              </path>

      </g>
  </svg>
);

const LogoText = () => (
  <div>CALIDRAW</div>
);

type LogoSize = "xs" | "small" | "normal" | "large" | "custom";

interface LogoProps {
  size?: LogoSize;
  withText?: boolean;
  style?: React.CSSProperties;
  /**
   * If true, the logo will not be wrapped in a Link component.
   * The link prop will be ignored as well.
   * It will merely be a plain div.
   */
  isNotLink?: boolean;
}

export const ExcalidrawLogo = ({
  style,
  size = "small",
  withText,
}: LogoProps) => {
  return (
    <div className={`ExcalidrawLogo is-${size}`} style={style}>
      <LogoIcon />
      {withText && <LogoText />}
    </div>
  );
};
